import React, { createContext, useContext, useState } from 'react'

const PublicPageContext = createContext()

export const PublicPageContextProvider = ({ children }) => {
  const [state, setState] = useState({})
  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }

  return <PublicPageContext.Provider value={{ state, updateState }}>{children}</PublicPageContext.Provider>
}

export const usePublicPageContext = () => {
  return useContext(PublicPageContext)
}
