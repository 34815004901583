import useInterval from '@use-it/interval'
import OpenModal from '../CMSComponent/Forms/Fields/openModal'
import PublicFooter from './Footer/footer'
import PublicHeader from './Header/header'
import { useLocation } from 'react-router-dom'
import { fetchUpdateApiData } from '../Redux/Reducers/CMS'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { checkIsMaintainanceRoute } from '../helper'
import MaintainanceNotice from './PageSpecificComponents/MaintainanceBanner/maintainanceNotice'
import { checkIsFutureDate, checkIsPastDate, convertDateInLocalTimezone } from '../dateHelper'
import { PublicPageContextProvider } from '../Context/publicPageContext'

const PublicPageWrapper = ({ children }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const updateApiData = useSelector((state) => state.CMS?.updateApiData)
  const show_alert_form = updateApiData?.site_maintenance?.show_alert_from
  const start_date = updateApiData?.site_maintenance?.start_date
  const searchParams = new URLSearchParams(location.search)
  const isShowOnlyContent = searchParams.get('isShowOnlyContent')

  useEffect(() => {
    dispatch(fetchUpdateApiData())
  }, [])

  useInterval(() => {
    if (localStorage.getItem('IS_INTERNET_ACTIVE')) {
    } else {
      dispatch(fetchUpdateApiData())
    }
  }, 60 * 1000)

  return (
    <PublicPageContextProvider>
      {!isShowOnlyContent && !checkIsMaintainanceRoute() && <PublicHeader />}
      {start_date && checkIsFutureDate(convertDateInLocalTimezone(start_date)) && checkIsPastDate(convertDateInLocalTimezone(show_alert_form)) && (
        <MaintainanceNotice />
      )}

      {children}
      {!isShowOnlyContent && !checkIsMaintainanceRoute() && <PublicFooter />}
      <OpenModal />
    </PublicPageContextProvider>
  )
}

export default PublicPageWrapper
