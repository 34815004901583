import { useRef, useState } from 'react'
import { Button } from 'reactstrap'
import { primary_color } from '../../../Helper/uiHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { isSuccessResponse } from '../../../helper'

const CustomTooltip = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const btnRef = useRef(null)

  if (row?.original?.status !== '0000') return null

  const showTooltip = isOpen && btnRef.current
  const rect = btnRef.current?.getBoundingClientRect?.()

  const onSendEmail = () => {
    setLoading(true)
    request('purchase/sent-confirmation-email', 'POST', { id: row?.original?.id }).then((res) => {
      isSuccessResponse(res, 'Email sent successfully!')
      setLoading(false)
    })
  }

  return (
    <div>
      <Button
        innerRef={btnRef}
        className='subtitle'
        style={{ background: primary_color, border: 'none' }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onClick={() => {
          onSendEmail()
        }}>
        {loading ? <i class='fa-solid fa-spinner fa-spin'></i> : <i className='fa-solid fa-envelope'></i>}
      </Button>

      {showTooltip && rect && (
        <div
          style={{
            position: 'fixed',
            top: rect.bottom + 5,
            left: rect.left,
            background: '#333',
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            zIndex: 9999,
            whiteSpace: 'nowrap',
          }}>
          Send confirmation email
        </div>
      )}
    </div>
  )
}

export default CustomTooltip
