import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { RiDoorLine } from 'react-icons/ri'

import Loader from 'react-loaders'
import useMediaQuery from '../../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../../Helper/uiHelper'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { request } from '../../../../Redux/Sagas/requests/api'

const Lockers = ({ data, formFieldValue = {}, currentLockerData, unitData, dependent_value, newLockerData, setNewData, formData }) => {
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery('(min-width:768px)')

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const payment_id = searchParams.get('payment_id')

  const [selectedLocker, setSelectedLocker] = useState()
  const [lockers, setLockers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const lockerColWidth = isDesktop ? 131.3 : 99.2
  const [lockerColCount, setLocakerCoCount] = useState(0)

  //* Convert API Data into vertical Array
  const displayVertically = (arr, rowCount) => {
    const output = arr.reduce((rows, item, index) => {
      const rowIndex = index % rowCount
      rows[rowIndex] = rows[rowIndex] || []
      rows[rowIndex].push(item)
      return rows
    }, [])

    return output
  }

  const fetchData = async (payload) => {
    setIsLoading(true)
    try {
      let payload = {
        field_data: 'items.locations',
        source_field: 'location_id',
        depend_field_value: dependent_value,
      }
      const response = await request('get-template-data-depend', 'POST', { ...payload })
      if (response?.status === 200 || response?.status === 202) {
        // const new_lockers = []
        const formattedData = displayVertically(response?.data?.data, response?.data?.lockerHeight)
        const new_lockers = formattedData?.map((item) => {
          return item?.map((dataItem) => {
            return {
              id: dataItem?.id,
              reserved: dataItem?.completed || dataItem?.reserved ? true : false,
              display: dataItem?.display,
              item_name: dataItem?.name,
              price: dataItem?.rate,
              questions: response?.data?.questions,
              total_cost: `${dataItem?.rate}`,
            }
          })
        })
        setLocakerCoCount(formattedData[0]?.length)
        setLockers(new_lockers)
      }
    } catch (e) {
      console.error('Error in get-template-data-depend : ', e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!dependent_value) {
      setLockers([])
      return
    }
    fetchData()
  }, [dependent_value])

  const onChangeValue = (reserved, value, item) => {
    if (reserved && !unitData.map((e) => e.item_id).includes(item?.id))
      return dispatch(
        setNotification({
          type: 'error',
          message: 'This locker is already reserved.',
        })
      )

    let itemToCheck = {
      ...item,
    }
    if (formData?.[0]) {
      itemToCheck = {
        ...itemToCheck,
        item_date_time_id: formData?.[0]?.item_date_time_id,
        location_id: formData?.[0]?.location_id,
        category_id: formData?.[0]?.category_id,
      }
    }

    let payload = {
      items: [itemToCheck],
      is_move_booking: true,
      // items: [item?.id],
    }
    request('check-item-availability', 'POST', payload).then((res) => {
      if (res.status === 200 || res?.status === 202) {
        setSelectedLocker(value)

        setNewData((old) => {
          return {
            // ...old,
            ...item,
            item_id: item?.id,
            item_id_label: item?.name || item?.item_name,
            questions: old?.questions,
          }
        })
        // setFormFieldValue({...updatedValue , questions : createDefaultAnswersNew(item?.questions)})
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message,
          })
        )
        fetchData()
      }
    })
  }
  const getBgColor = (item) => {
    if (newLockerData?.item_id === item?.id) {
      return '#06880BCC'
    } else if (currentLockerData?.item_id === item?.id) {
      return '#059669CC'
    } else if (item?.display === 0) {
      return '#FFFFFF'
    } else if (item?.reserved) {
      return '#F87171'
    } else {
      return primary_color
    }
  }

  if (isLoading)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        <Loader type='ball-pulse' color={primary_color} />
      </div>
    )
  if (!lockers.length && dependent_value)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        No lockers found.
      </div>
    )
  console.log('lockers:::::::', lockers)
  return (
    <div className='mt-2'>
      {lockers?.length ? (
        <div className='d-flex align-items-center mb-5 mt-5 subtitle1'>
          <div className='rounded-pill mr-2 subtitle1' style={{ height: '1rem', width: '1rem', background: '#059669CC' }}></div> Currently Booked
          <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: primary_color }}></div> Available
          <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#06880BCC' }}></div> Booked
          <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#F87171' }}></div> Reserved
        </div>
      ) : (
        <></>
      )}
      <div className=' mb-2 !w-100 custom-scrollbar' style={{ overflow: 'auto' }}>
        <div style={{ width: lockerColWidth * lockerColCount + 'px' }} className='d-flex flex-wrap'>
          {lockers?.map((locakerItem, index) => {
            return locakerItem?.map((item, i) => {
              const isDisplayZero = item?.display === 0 ? true : false
              return (
                <div
                  key={'lockers' + index + i}
                  onClick={() => {
                    onChangeValue(item?.reserved, item?.id, item)
                  }}
                  className=' position-relative'
                  style={{
                    width: isDesktop ? '8rem' : '6rem',
                    margin: '0.1rem',
                    height: isDesktop ? '10rem' : '9rem',
                    background: getBgColor(item),
                    cursor: !item?.reserved || !isDisplayZero ? 'pointer' : 'default',
                  }}>
                  {!isDisplayZero ? (
                    <div
                      className='m-1 position-absolute subtitle1'
                      style={{
                        left: '0.5rem',
                        top: '0.25rem',
                        background: 'white',
                        padding: '0rem 0.5rem',
                        borderRadius: '14px',
                        fontWeight: '500',
                      }}>
                      <div>£{item?.price}</div>
                    </div>
                  ) : null}

                  <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}>
                    {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
                    <RiDoorLine style={{ color: '#DADADA', fontSize: '6rem' }} />
                  </div>
                  <div
                    className='text-align-center mt-2 subtitle1'
                    style={{
                      color: '#EDF0F7',
                      position: 'absolute',
                      bottom: '1rem',
                      fontWeight: '500',
                      left: '50%',
                      transform: 'translate(-50%)',
                    }}>
                    {item?.item_name}
                  </div>
                </div>
              )
            })
          })}
        </div>
      </div>
    </div>
  )
}

export default Lockers
