import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { dummyBookingDetail } from '../../../CMSComponent/PageVariation/PublicPage/dummyPage'
import { cms, getDomainDetailsById, tidyName, updateDomainDetailInRedux } from '../../../helper'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { primary_color } from '../../../Helper/uiHelper'
import moment from 'moment'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSHeroSearch from '../../CMSWidget/HeroSearch/cmsHeroSearch'
import CMSBookingDetails from '../../CMSWidget/BookingReservation/cmsBookingReservation'
import { useSelector } from 'react-redux'
import './payment.scss'

let dummy_data = [
  { name: 'Child name:', value: 'Nutan Khangar' },
  { name: 'Reservation ID:', value: '96458122' },
  { name: 'Start rent:', value: '01 Sept 2023' },
  { name: 'Locker Block:', value: 'First English school' },
  { name: 'Locker Number:', value: '18' },
  { name: 'End rent:', value: '31 Aug 2023' },
]
const default_image = 'https://test-api.ionicbox.com/storage/files/1/GyexiOYukHj8.png'

const BookingDetails = ({ bookingDetails, setBookingDetails, data, parentData, form, widget_uuid }) => {
  const { location } = useHistory()
  const isPaymentPage = location.pathname === '/payment-success-list'
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const isDesktop = useMediaQuery('(min-width:768px)')
  const editMode = useSelector((state) => state.CMS.editMode)
  const sectionRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()
  const parent_id = query.get('parent_id')
  const [parentDetails, setParentDetails] = useState()

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: rect.height + 100,

        //  left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: isPaymentPage ? '40vw' : '60vw',
      })
    }
  }

  let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer') || '')
  let current_storage_timer = storage_timer?.[parentData?.id]

  useEffect(() => {
    const fields = form?.component_call_fields?.map((item) => item?.field_id)
    if (!window.location.pathname?.includes('payment-success-list') && parent_id) {
      if (getDomainDetailsById(parent_id)) {
        setParentDetails(getDomainDetailsById(parent_id))
      } else {
        request('retrieve-data-by-table', 'POST', {
          source_table: 'domains',
          row_id: parent_id,
        }).then((res) => {
          if (res?.data) {
            setParentDetails(res?.data?.data)
            updateDomainDetailInRedux(parent_id, res?.data?.data)
          }
        })
      }
    }

    request('get-booking', 'POST', {
      uuid: query.get('uuid') ? query.get('uuid') : current_storage_timer?.session_uuid,
      fields,
      payment_id: query.get('payment_id') && parseInt(query.get('payment_id')),
      is_link: !!query.get('link_id'),
    }).then((res) => {
      setBookingDetails(res?.data)
    })
  }, [parentData?.id])

  if (!bookingDetails) return
  const getBookingData = (fields = [], data = {}) => {
    const booking_data = []
    data?.bookingReservation?.units?.map((item) => {
      const data_arr = []
      fields?.forEach((field) => {
        if (field?.field_id === 'pre_time' || field?.field_id === 'post_time') {
          data_arr?.push({
            name: field?.cms_key ? cms(field?.cms_key) : tidyName(field?.name),
            value: item[field?.field_id] && moment(item[field?.field_id]).format('Do MMM, YYYY'),
          })
        } else {
          //* hide Field until Featuer ready
          if (field?.field_id !== 'reservation_id') {
            data_arr?.push({
              name: field?.cms_key ? cms(field?.cms_key) : tidyName(field?.name),
              value: item[`${field?.field_id}_label`] || item[field?.field_id],
            })
          }
        }
      })
      // Need to change the logic after backend response got chaange for Child Name
      if (item.questions && item?.questions[0]?.answer && item?.questions[1]?.answer) {
        for (let i = 0; i < data_arr.length; i++) {
          if (isPaymentPage)
            if (data_arr[i].name.trim().toLowerCase() === 'pupil name') {
              data_arr[i].value = item.questions[0].answer
            }
          if (data_arr[i].name.trim().toLowerCase() === 'pupil surname') {
            data_arr[i].value = item.questions[1].answer
          } else {
            if (data_arr[i].name.trim().toLowerCase() === 'child name') {
              data_arr[i].value = item.questions[0].answer + ' ' + item.questions[1].answer
              break
            }
          }
        }
      }
      booking_data?.push(data_arr)
    })

    return booking_data
  }
  const booking_data = getBookingData(form?.component_call_fields, bookingDetails)

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  return (
    <div
      className={isPaymentPage ? '' : `col-md-12 ml-auto mr-auto ${modalPosition && 'active-cms-edit'}`}
      onClick={handleSectionClick}
      ref={sectionRef}
      style={isDesktop && !isPaymentPage ? { marginTop: '1rem' } : { marginTop: '2.5rem', paddingInline: '1rem' }}>
      <div className={isDesktop ? '' : ''} style={{ marginLeft: 'auto' }}>
        <div class={isPaymentPage ? 'mb-5' : 'mb-3'}>
          {parentDetails && window.location.pathname?.includes('book-my-locker') && (
            <>
              {' '}
              <div className={`mb-3 ${isDesktop ? '' : 'mt-5'}`}>
                <div className={`d-flex align-items-center `}>
                  <div>
                    {parentDetails?.logo ? (
                      <img
                        src={parentDetails?.logo ? process.env.REACT_APP_STORAGE + parentDetails?.logo : default_image}
                        width='60'
                        height='60'
                        className='me-3'
                        style={{ borderRadius: '50%', objectFit: 'cover', border: '1px solid #495057' }}
                      />
                    ) : (
                      <div style={{ padding: '6px', borderRadius: '50%', border: '1px solid #495057' }}>
                        <img src={default_image} width='50' height='50' className='me-3' style={{}} />
                      </div>
                    )}
                  </div>
                  <div style={{ flex: 1 }} className='ml-4'>
                    <div className='font-weight-bold body1' style={{ color: '#495057' }}>
                      {parentDetails?.domain_name}
                    </div>
                  </div>
                </div>
                <hr></hr>
              </div>
            </>
          )}
          {!isPaymentPage && (
            <div
              className='mb-3 font-weight-bold booking-widget-heading body1'
              dangerouslySetInnerHTML={{ __html: data?.heading?.replaceAll('h6', 'div') || '<div><b>Booking Details</b></div>' }}></div>
          )}

          {/* <div class='body1 font-weight-bold mb-3 content-widget' style={{ color: primary_color }}>
            {!isPaymentPage ? <>{cms(data?.heading) || 'Booking Details'}</> : <></>}
          </div> */}

          <div className={!isPaymentPage ? 'row' : ''} style={isPaymentPage ? {} : {}}>
            {booking_data.map((item, i) => (
              <>
                {isPaymentPage ? (
                  <div class='font-weight-bold mb-3 body1' style={{ color: primary_color }}>
                    {`Pupil ${i + 1}`}
                  </div>
                ) : (
                  <></>
                )}
                {item?.map((element, index) => {
                  return (
                    <>
                      {isPaymentPage ? (
                        <div className={' p-0'} key={index}>
                          <div
                            className='pt-2 pl-2 pb-2'
                            style={{ background: index % 2 === 0 ? '' : '#049FDB1A', display: 'grid', gridTemplateColumns: '40% 60%' }}>
                            <div className=' font-weight-bold subtitle1'>{element.name}</div>
                            <div className='subtitle1'>{element.value}</div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={'col-md-6'} key={index}>
                            <div className='row mb-3'>
                              <div className='col-12 font-weight-bold subtitle1' style={{ minWidth: '8rem' }}>
                                {element.name}:{' '}
                              </div>
                              <div className='col-12 subtitle1 text-muted'>{element.value}</div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )
                })}
                {!isPaymentPage ? (
                  <div className={isPaymentPage ? 'w-100' : 'w-100 mt-3'}>
                    <hr className='mt-0'></hr>
                  </div>
                ) : (
                  <div className='mt-5'></div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSBookingDetails
            data={data}
            toggleModal={() => {
              onCloseModal()
            }}
            widget_uuid={widget_uuid}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default BookingDetails
